import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import {BeltRank, BeltRanks} from "../domain/models/models";

interface ProfileSummaryProps {
  userName: string;
  gymName?: string | null;
  beltRank?: BeltRank | null;
  avatarUrl?: string;
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({ userName, gymName, beltRank, avatarUrl }) => {
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (avatarUrl) {
      const img = new Image();
      img.onload = () => setAvatarSrc(avatarUrl);
      img.onerror = () => setAvatarSrc(undefined);
      img.src = avatarUrl;
    }
  }, [avatarUrl]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
      <Avatar src={avatarSrc} alt={userName} sx={{ width: 64, height: 64 }} >
        {userName.charAt(0).toUpperCase()}
      </Avatar>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body1">{userName}</Typography>
        <Typography sx={{fontSize: '0.8rem'}} variant="body2">{gymName ?? '체육관 등록이 필요합니다'}</Typography>
        <Typography sx={{fontSize: '0.8rem'}} variant="body2">{beltRank ? BeltRanks.toDisplayText(beltRank) : '미설정(관장님께 문의하세요)'}</Typography>
      </Box>
    </Box>
  );
};

export default ProfileSummary;