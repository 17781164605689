import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Config} from "./config/config";
import {PayloadType, ShareAuthToken} from "idl-typescript";
import supabaseClient from "./lib/supabase-client/supabaseClient";
import {WebviewCommunicator} from "./adapters/webview/webview_communicator";

Config.getInstance();

WebviewCommunicator.getInstance()
  .registerMessageHandler<ShareAuthToken>(
    PayloadType.PayloadTypeShareAuthToken,
    async (payload) => {
      try {
        const authResponse = await supabaseClient.auth.setSession({
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        });

        console.info(JSON.stringify(authResponse));
        console.info('Successfully shared auth token with webview');
        console.info(JSON.stringify((await supabaseClient.auth.getUser()).data.user))

      } catch (error) {
        console.error('Error sharing auth token with webview:', error);
      }
      return;
    }
  );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
