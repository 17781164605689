import React, {useState} from 'react';
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Example, PayloadType} from "idl-typescript";
import {WebviewCommunicator} from "../adapters/webview/webview_communicator";


const WebviewCommunicationPage: React.FC = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState<string>('');

  WebviewCommunicator.getInstance()
    .registerMessageHandler<Example>(
      PayloadType.PayloadTypeExample,
      async (payload) => {
        setMessage(payload.message);
        return;
      }
    );

  return (
    <Box
    height={500}>
      <button onClick={sendMessageToFlutter}>Send Message to Flutter</button>
      <p>Message from Flutter: {message}</p>
    </Box>
  );
};

const sendMessageToFlutter = () => {
  return WebviewCommunicator.getInstance()
    .sendMessageToNative<Example>(PayloadType.PayloadTypeExample, {message: "hello from react"});
};

export default WebviewCommunicationPage;
