import {SupabaseClient} from "@supabase/supabase-js";
import supabaseClient from "../../lib/supabase-client/supabaseClient";
import {Attendance} from "../../domain/models/models";
import {format, parseISO} from "date-fns";

export class AttendanceDTO {
  id: number;
  user_id: number;
  check_in_time: Date;
  gym_id: number;
  created_at: Date;

  constructor(
    id: number,
    user_id: number,
    check_in_time: Date,
    gym_id: number,
    created_at: Date
  ) {
    this.id = id;
    this.user_id = user_id;
    this.check_in_time = check_in_time;
    this.gym_id = gym_id;
    this.created_at = created_at;
  }

  // Method to serialize (marshal) the object to JSON
  static toJson(dto: AttendanceDTO): Record<string, any> {
    return {
      id: dto.id.toString(), // bigints are converted to strings for JSON compatibility
      user_id: dto.user_id.toString(),
      check_in_time: dto.check_in_time.toUTCString(),
      gym_id: dto.gym_id.toString(),
      created_at: dto.created_at.toUTCString()
    };
  }

  // Method to deserialize (unmarshal) JSON to an AttendanceDto object
  static fromJson(json: Record<string, any>): AttendanceDTO {
    return new AttendanceDTO(
      json.id,
      json.user_id,
      new Date(convertUTCToLocal(json.check_in_time)),
      json.gym_id,
      new Date(convertUTCToLocal(json.created_at))
    );
  }
}

export class AttendanceRepository {
  private static instance: AttendanceRepository;
  private supabase: SupabaseClient<any, "public", any>;

  private constructor() {
    // Initialize the Supabase client with your project details
    this.supabase = supabaseClient;
  }

  public static getInstance(): AttendanceRepository {
    if (!AttendanceRepository.instance) {
      AttendanceRepository.instance = new AttendanceRepository();
    }
    return AttendanceRepository.instance;
  }

  public async listAttendancesByUserID(userID: number): Promise<Attendance[] | null> {
    const { data, error } = await this.supabase
      .from('attendance')
      .select('*')
      .eq('user_id', userID)
      .order('created_at', { ascending: true });


    if (error) {
      console.error('Error fetching attendance:', error.message);
      throw error;
    }

    if (!data) {
      return null;
    }

    return data.map((record) => AttendanceDTO.fromJson(record)).map((dto) => {
      return {
        id: dto.id,
        userID: dto.user_id,
        checkInTime: dto.check_in_time,
        gymID: dto.gym_id,
        createdAt: dto.created_at
      };
    });
  }
}

function convertUTCToLocal(timestamp: string): string {
  // Parse the ISO string from Supabase (e.g., '2023-10-25T13:45:00Z')
  const utcDate = parseISO(timestamp);

  // Format it in the local timezone
   // Customize format as needed
  return format(utcDate, 'yyyy-MM-dd HH:mm:ss');
}

