import {useEffect, useState} from "react";
import {AppUserRepository} from "../adapters/repository/user_repository";
import {AppUser} from "../domain/models/models";

export function useUserByAuthID(authID: string | null) {
  const [user, setUser] = useState<AppUser | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!authID) {
      return
    }

    async function fetchUser() {
      try {
        setLoading(true)
        setUser(await AppUserRepository.getInstance().fetchByAuthID(authID!))
      } catch (e) {


        setError(e instanceof Error ? e : new Error('An unknown error occurred: ' + JSON.stringify(e, Object.getOwnPropertyNames(e))))
      } finally {
        setLoading(false)
      }
    }

    fetchUser()
  }, [authID])

  return { user, loading, error }
}