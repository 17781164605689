/**
 * An object representing a message with a payload type and payload content
 */
export interface Message {
    /**
     * A string containing JSON of the DTO specified by payload_type
     */
    readonly payload: string;
    /**
     * An enum describing what the payload is
     */
    readonly payload_type: PayloadType;
}

/**
 * An enum describing what the payload is
 */
export enum PayloadType {
    PayloadTypeExample = "PAYLOAD_TYPE_EXAMPLE",
    PayloadTypeShareAuthToken = "PAYLOAD_TYPE_SHARE_AUTH_TOKEN",
}
