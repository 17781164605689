import {Message, PayloadType, ShareAuthToken} from "idl-typescript";

declare global {
  interface Window {
    flutter_inappwebview: {
      callHandler: (handlerName: string, ...args: any[]) => Promise<any>;
    };
  }
}

// or using a global flag variable
let isFlutterInAppWebViewReady = false;
window.addEventListener("flutterInAppWebViewPlatformReady", function(event) {
  isFlutterInAppWebViewReady = true;
});

export const isMessage = (message: any): message is Message => {
  return message.payload_type && message.payload;
};

export class WebviewCommunicator {
  private static instance: WebviewCommunicator;

  private constructor() {}

  public static getInstance(): WebviewCommunicator {
    if (!WebviewCommunicator.instance) {
      WebviewCommunicator.instance = new WebviewCommunicator();
    }
    return WebviewCommunicator.instance;
  }

  registerMessageHandler<T extends Object>(payloadType: PayloadType, handler: (payload: T) => void | Promise<void>) {
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.type != 'message' || !event.data || typeof event.data !== 'string') {
        return
      }

      let message: Message;
      try {
        message = JSON.parse(event.data);
      } catch (error) {
        console.error('Error parsing message:', error);
        return;
      }

      if (!isMessage(message)) {
        return;
      }

      if (message.payload_type !== payloadType) {
        return;
      }

      const payload: T = JSON.parse(message.payload);
      handler(payload);
    });
  }

  sendMessageToNative<T>(payloadType: PayloadType, payloadObject: T) {
    if (isFlutterInAppWebViewReady) {
      const message: Message = {
        payload_type: payloadType,
        payload: JSON.stringify(payloadObject)
      };
      // Send message to Flutter app
      return window.flutter_inappwebview.callHandler('flutterHandler', JSON.stringify(message));
    }
  }
}