export function getEnv(key: string, defaultValue: string) {
    let value = process.env[key];
    if (!value) {
        value = defaultValue;
    }
    return value;
}

export function mustGetEnv(key: string) {
    let value = process.env[key];
    if (!value) {
        throw new Error(`환경 변수 ${key}가 설정되지 않았습니다.`);
    }
    return value;
}
