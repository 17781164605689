import {createClient} from "@supabase/supabase-js";
import {Config} from "../../config/config";

// 설정된 Supabase 환경 변수 값이 없으면 staging 환경으로 설정합니다.
const supabaseURL = Config.getInstance().supabaseURL;
const supabaseAnonKey = Config.getInstance().supabaseAnonKey;
const supabaseClient = createClient(supabaseURL, supabaseAnonKey);

// 하나만 export 할 때 더 깔끔하게 import 할 수 있도록 export default를 사용합니다.
// ref: https://velog.io/@woongstaa/React-export-default%EC%9D%98-%EC%9D%98%EB%AF%B8
export default supabaseClient;
