export enum BeltRank {
  whiteGrauZero = 'WHITE_GRAU_ZERO',
  whiteGrauOne = 'WHITE_GRAU_ONE',
  whiteGrauTwo = 'WHITE_GRAU_TWO',
  whiteGrauThree = 'WHITE_GRAU_THREE',
  whiteGrauFour  = 'WHITE_GRAU_FOUR',
  blueGrauZero = 'BLUE_GRAU_ZERO',
  blueGrauOne = 'BLUE_GRAU_ONE',
  blueGrauTwo = 'BLUE_GRAU_TWO',
  blueGrauThree = 'BLUE_GRAU_THREE',
  blueGrauFour = 'BLUE_GRAU_FOUR',
  purpleGrauZero = 'PURPLE_GRAU_ZERO',
  purpleGrauOne = 'PURPLE_GRAU_ONE',
  purpleGrauTwo = 'PURPLE_GRAU_TWO',
  purpleGrauThree = 'PURPLE_GRAU_THREE',
  purpleGrauFour = 'PURPLE_GRAU_FOUR',
  brownGrauZero = 'BROWN_GRAU_ZERO',
  brownGrauOne = 'BROWN_GRAU_ONE',
  brownGrauTwo = 'BROWN_GRAU_TWO',
  brownGrauThree = 'BROWN_GRAU_THREE',
  brownGrauFour = 'BROWN_GRAU_FOUR',
}

export namespace BeltRanks {
  export function toDisplayText(rank: BeltRank): string {
    switch (rank) {
      case BeltRank.whiteGrauZero:
        return '⬜ LV 0';
      case BeltRank.whiteGrauOne:
        return '⬜ LV 1';
      case BeltRank.whiteGrauTwo:
        return '⬜ LV 2';
      case BeltRank.whiteGrauThree:
        return '⬜ LV 3';
      case BeltRank.whiteGrauFour:
        return '⬜ LV 4';
      case BeltRank.blueGrauZero:
        return '🟦 LV 0';
      case BeltRank.blueGrauOne:
        return '🟦 LV 1';
      case BeltRank.blueGrauTwo:
        return '🟦 LV 2';
      case BeltRank.blueGrauThree:
        return '🟦 LV 3';
      case BeltRank.blueGrauFour:
        return '🟦 LV 4';
      case BeltRank.purpleGrauZero:
        return '🟪 LV 0';
      case BeltRank.purpleGrauOne:
        return '🟪 LV 1';
      case BeltRank.purpleGrauTwo:
        return '🟪 LV 2';
      case BeltRank.purpleGrauThree:
        return '🟪 LV 3';
      case BeltRank.purpleGrauFour:
        return '🟪 LV 4';
      case BeltRank.brownGrauZero:
        return '🟫 LV 0';
      case BeltRank.brownGrauOne:
        return '🟫 LV 1';
      case BeltRank.brownGrauTwo:
        return '🟫 LV 2';
      case BeltRank.brownGrauThree:
        return '🟫 LV 3';
      case BeltRank.brownGrauFour:
        return '🟫 LV 4';
    }
  }

  /**
   * Converts a SCREAMING_SNAKE_CASE string to its corresponding BeltRank enum value.
   * @param value The SCREAMING_SNAKE_CASE string.
   * @returns The corresponding BeltRank enum value, or undefined if not found.
   */
  export function fromString(value: string): BeltRank | undefined {
    switch (value) {
      case 'WHITE_GRAU_ZERO':
        return BeltRank.whiteGrauZero;
      case 'WHITE_GRAU_ONE':
        return BeltRank.whiteGrauOne;
      case 'WHITE_GRAU_TWO':
        return BeltRank.whiteGrauTwo;
      case 'WHITE_GRAU_THREE':
        return BeltRank.whiteGrauThree;
      case 'WHITE_GRAU_FOUR':
        return BeltRank.whiteGrauFour;
      case 'BLUE_GRAU_ZERO':
        return BeltRank.blueGrauZero;
      case 'BLUE_GRAU_ONE':
        return BeltRank.blueGrauOne;
      case 'BLUE_GRAU_TWO':
        return BeltRank.blueGrauTwo;
      case 'BLUE_GRAU_THREE':
        return BeltRank.blueGrauThree;
      case 'BLUE_GRAU_FOUR':
        return BeltRank.blueGrauFour;
      case 'PURPLE_GRAU_ZERO':
        return BeltRank.purpleGrauZero;
      case 'PURPLE_GRAU_ONE':
        return BeltRank.purpleGrauOne;
      case 'PURPLE_GRAU_TWO':
        return BeltRank.purpleGrauTwo;
      case 'PURPLE_GRAU_THREE':
        return BeltRank.purpleGrauThree;
      case 'PURPLE_GRAU_FOUR':
        return BeltRank.purpleGrauFour;
      case 'BROWN_GRAU_ZERO':
        return BeltRank.brownGrauZero;
      case 'BROWN_GRAU_ONE':
        return BeltRank.brownGrauOne;
      case 'BROWN_GRAU_TWO':
        return BeltRank.brownGrauTwo;
      case 'BROWN_GRAU_THREE':
        return BeltRank.brownGrauThree;
      case 'BROWN_GRAU_FOUR':
        return BeltRank.brownGrauFour;
    }
    return undefined;
  }

  /**
   * Utility function to convert camelCase or PascalCase strings to SCREAMING_SNAKE_CASE.
   * @param str The input string in camelCase or PascalCase.
   * @returns The converted SCREAMING_SNAKE_CASE string.
   */
  function toScreamingSnakeCase(str: string): string {
    return str
      .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase
      .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2') // Add underscore between two uppercase letters when the second is followed by lowercase
      .toUpperCase();
  }
}

export interface AppUser {
  userID: number;
  authUserID: string;
  displayName?: string | null;
  gym?: Gym | null;
  beltRank?: BeltRank | null;
  profileImageURL?: string | null;
}

export interface Gym {
  gymID: number;
  name: string;
  address: string;
  mobile?: string | null;
}

export interface Attendance {
  id: number;
  userID: number;
  checkInTime: Date;
  gymID: number;
  createdAt: Date;
}
