import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './components/HomePage';
import WebviewCommunicationPage from "./components/WebviewCommunicationPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/webview-communication" element={<WebviewCommunicationPage/>}/>
      </Routes>
    </Router>
  );
};

export default App;
