import {useEffect, useState} from "react";
import {AppUserRepository} from "../adapters/repository/user_repository";
import {AppUser, Attendance} from "../domain/models/models";
import {AttendanceRepository} from "../adapters/repository/attendance_repository";

export function useAttendancesByUserID(userID: number | null) {
  const [attendances, setAttendances] = useState<Attendance[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!userID) {
      return
    }

    async function fetchAttendances() {
      try {
        setLoading(true)
        setAttendances(await AttendanceRepository.getInstance().listAttendancesByUserID(userID!))
      } catch (e) {


        setError(e instanceof Error ? e : new Error('An unknown error occurred: ' + JSON.stringify(e, Object.getOwnPropertyNames(e))))
      } finally {
        setLoading(false)
      }
    }

    fetchAttendances()
  }, [userID])

  return { attendances, loading, error }
}
