import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import ProfileSummary from './ProfileSummary';
import Calendar, { CalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "App.css";

import {useUserByAuthID} from "../hooks/user-hooks";
import supabaseClient from "../lib/supabase-client/supabaseClient";
import {TileArgs} from "react-calendar/src/shared/types";
import {useAttendancesByUserID} from "../hooks/attendance-hooks";
import {Attendance} from "../domain/models/models";
import {addMonths, format, subMonths} from "date-fns";

// Define the type for the query result
interface AttendanceSummary {
  total_attendance: number;
  current_month_attendance: number;
}

const HomePage: React.FC = () => {
    const [authID, setAuthID] = useState<string | null>(null)

    const { user, loading, error} = useUserByAuthID(authID)

    const today = new Date();
    // Define the activeStartDate state with Date type
    const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

    // Handle month navigation, updating the activeStartDate
  const handleActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date }) => {
    if (activeStartDate) {
      setActiveStartDate(activeStartDate);
    }
  };

    // Calculate previous and next month dates
    const minDate: Date = subMonths(activeStartDate, 2);
    const maxDate: Date = addMonths(activeStartDate, 2);


    const [summary, setAttendanceSummary] = useState<AttendanceSummary | null>(null);
    const [isLoadingSummary, setLoading] = useState(true);
    const [errorSummary, setError] = useState<Error | null>(null);

    const { attendances, loading: isLoadingAttendances, error: errorAttendances } = useAttendancesByUserID(user?.userID ?? null);
    const groupedAttendances = groupAttendancesByDate(attendances ?? []);

    useEffect(() => {
        fetchAuthID();
        fetchAttendanceSummary();
    }, []);

    const fetchAttendanceSummary = async () => {
      try {
        const { data, error } = await supabaseClient
          .rpc<'get_attendance_summary', AttendanceSummary>('get_attendance_summary', { user_id: 3 })
          .single();

        if (error) throw error;

        setAttendanceSummary(data);
      } catch (e) {
        let err = e instanceof Error ? e : new Error('An unknown error occurred: ' + JSON.stringify(e, Object.getOwnPropertyNames(e)));
        setError(err);
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchAuthID = async () => {
        const { data: { session } } = await supabaseClient.auth.getSession();
        if (session?.user?.id) {
            setAuthID(session.user.id);
        }
    };

    if (!authID) {
        return <div>Loading...</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!user) {
        return <div>User not found</div>;
    }

    const tileClassName = ({ date, view, activeStartDate }: TileArgs) => {
        if (view === "month") {
          const dateKey = getLocalDate(date);
          if (groupedAttendances[dateKey]) {
            return 'present';
          }
        }
        return null;
    };
    return (
        <div className="app">
          <Box sx={{ paddingX: 2 }}>
            <ProfileSummary userName={user.displayName ?? 'Unknown'} gymName={`${user.gym?.name}` ?? ''} beltRank={user.beltRank} avatarUrl={user.profileImageURL ?? 'placeholder'}/>
          </Box>
          <div className="attendance-summary">
            <h3>훈련 현황</h3>
            <div className="attendance-details">
            <div>
              <p>✋</p>
              <p>누적</p>
              <p>{isLoadingSummary ? '로딩 중' : errorSummary?.message ?? summary?.total_attendance ?? 0}</p>
            </div>
            <div>
              <p>👊</p>
              <p>이번 달</p>
              <p>{isLoadingSummary ? '로딩 중' : errorSummary?.message ?? summary?.current_month_attendance ?? 0}</p>
            </div>
            </div>
          </div>
          <div className="calendar">
            <h3>훈련 일지</h3>
            <Calendar
              tileClassName={tileClassName}
              value={today}
              locale="ko"
              activeStartDate={activeStartDate}
              onActiveStartDateChange={handleActiveStartDateChange as CalendarProps['onActiveStartDateChange']}
              formatDay={(locale, date) => format(date, 'd')}
              formatMonthYear={(locale, date) => format(date, 'MMMM yyyy')}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </div>
      );
    };

// Utility function to group attendances by date
function groupAttendancesByDate(attendances: Attendance[]): Record<string, Attendance[]> {
  return attendances.reduce((acc, attendance) => {
    const dateKey = getLocalDate(attendance.checkInTime);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(attendance);
    return acc;
  }, {} as Record<string, Attendance[]>);
}

function getLocalDate(date: Date): string {
  // Format the Date instance in the local timezone
   // Customize format as needed
  return format(date, 'yyyy-MM-dd');
}


export default HomePage;
