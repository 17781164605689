// define a config class that stores config values read from the environment
import {mustGetEnv} from "../lib/env/env";

export class Config {
    private static instance: Config;

    private readonly _supabaseURL: string;
    private readonly _supabaseAnonKey: string;

    private constructor() {
        this._supabaseURL = mustGetEnv('REACT_APP_SUPABASE_URL');
        this._supabaseAnonKey = mustGetEnv('REACT_APP_SUPABASE_ANON_KEY');
    }

    public static getInstance(): Config {
        if (!Config.instance) {
            Config.instance = new Config();
        }
        return Config.instance;
    }

    get supabaseURL(): string {
        return this._supabaseURL;
    }

    get supabaseAnonKey(): string {
        return this._supabaseAnonKey;
    }
}
